.controw{
  transition: height 0.4s;
}
.carousel-inner{
  height: 100%;
}

#info_bar{
  background: #1E2335;
  color: white;
  font-size: 2vh;
  font-family: Scope One;
}

.carcaption{
  font-size: 1pt;
  font-family: Scope One;
}

.appdraw_text{
  font-size: 1.3vh;
  font-family: Scope One;
}

#footer_bar{
  background: #1E2335;
}

#header_bar{
  font-size: 1.6vh;
  font-family: Scope One;
}

.grid_item{
  cursor: pointer;
}

#sshow_bar{
  position: relative;
  overflow: hidden;
}
